/** @jsx jsx */
import React from "react" // eslint-disable-line no-unused-vars
import PropTypes from "prop-types"
import { jsx, useThemeUI } from "theme-ui"

import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Box from "./box"
import Button from "./button"
import Heading from "./heading"
import Icon from "./icon"
import Inline from "./inline"
import Paragraph from "./paragraph"
import Stack from "./stack"
import Text from "./text"

const HyperCardStacked = ({
  alt,
  attribution,
  cta,
  description,
  headingElement,
  image,
  title,
  to,
}) => {
  const context = useThemeUI()
  const { theme } = context

  const srcImage = getImage(image)

  return (
    <Link
      to={to}
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        position: "relative",
        ".hyper-card-image, .hyper-card-box": {
          transition: `all ${theme.transitions["main"]}`,
        },
        ":hover .hyper-card-image, :focus .hyper-card-image": {
          transform: "scale(1.1)",
        },
        ":hover .hyper-card-button, :focus .hyper-card-button": {
          borderColor: "black",
        },
        ":hover .hyper-card-button *, :focus .hyper-card-button *": {
          color: "black",
        },
        ":hover .hyper-card-box, :focus .hyper-card-box": {
          bg: "primary",
        },
        ":hover .hyper-card-heading, :focus .hyper-card-heading": {
          color: "black",
        },
        ":hover .hyper-card-description, :focus .hyper-card-description": {
          color: "black",
        },
      }}
    >
      {image && (
        <Box
          sx={{
            height: [32, 48, 48, 56],
            overflow: "hidden",
            flexShrink: 0,
            position: "relative",
          }}
        >
          <GatsbyImage
            className="hyper-card-image"
            alt={alt}
            image={srcImage}
            sx={{
              position: "absolute",
              left: 0,
              top: 0,
              right: 0,
              bottom: 0,
            }}
          />
          {attribution && (
            <Box position="absolute" bottom={0} left={0} right={0} p={3}>
              <Text size="10px" role="presentation" color="white" as="div" sans>
                <Inline space={1} alignX="right">
                  <span>Bild:</span>
                  <span>{attribution}</span>
                </Inline>
              </Text>
            </Box>
          )}
        </Box>
      )}
      <Box
        className="hyper-card-box"
        bg="muted"
        p={[6, 8]}
        sx={{
          display: "flex",
          width: "100%",
          height: "100%",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ pb: [6, 8] }}>
          <Stack space={3}>
            <Heading
              as={headingElement}
              className="hyper-card-heading"
              lang="de"
              hyphens="auto"
              level={5}
            >
              {title}
            </Heading>
            {description && (
              <Paragraph className="hyper-card-description" size={[2, 2, 3]}>
                {description}
              </Paragraph>
            )}
          </Stack>
        </Box>
        {cta && (
          <Box sx={{ flexShrink: 0 }}>
            <Button className="hyper-card-button" secondary>
              {cta}
            </Button>
          </Box>
        )}
      </Box>
      <Box
        position="absolute"
        bottom={0}
        right={0}
        zIndex="10"
        className="hyper-card-square"
        bg="primary"
        color="black"
        p={3}
        textAlign="center"
        display="inline-flex"
        alignX="center"
        alignY="center"
      >
        <Icon icon="arrow-right" />
      </Box>
    </Link>
  )
}

HyperCardStacked.propTypes = {
  alt: PropTypes.string,
  cta: PropTypes.string,
  headingElement: PropTypes.string,
  description: PropTypes.string,
  title: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
}

HyperCardStacked.defaultProps = {
  headingElement: "h4",
  alt: "",
}

export default HyperCardStacked
