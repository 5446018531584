import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Grid from "../../components/grid"
import HyperCardStacked from "../../components/hyper-card-stacked"

const TeaserDigital = () => {
  const data = useStaticQuery(graphql`
    query TeaserDigital {
      dauerausstellung: file(
        relativePath: { eq: "startseite/dauerausstellung.jpg" }
      ) {
        ...mediumImage
      }
      kurse: file(relativePath: { eq: "startseite/kurse.jpg" }) {
        ...mediumImage
      }
      soundboard: file(relativePath: { eq: "startseite/soundboard.jpg" }) {
        ...mediumImage
      }
      podcast: file(relativePath: { eq: "backdrops/turmgespraeche.jpg" }) {
        ...mediumImage
      }
      sonderausstellungen: file(
        relativePath: { eq: "startseite/sonderausstellungen.jpg" }
      ) {
        ...mediumImage
      }
      workshops: file(relativePath: { eq: "backdrops/workshops.jpg" }) {
        ...mediumImage
      }
      archiv: file(relativePath: { eq: "ausstellungen/klimavitrine.jpg" }) {
        ...mediumImage
      }
      mediaguide: file(relativePath: { eq: "mediaguide/teaser-4.jpg" }) {
        ...mediumImage
      }
      literaturpfad: file(relativePath: { eq: "literaturpfad/teaser.jpg" }) {
        ...mediumImage
      }
      live: file(relativePath: { eq: "startseite/videos.jpg" }) {
        ...mediumImage
      }
      videos: file(relativePath: { eq: "startseite/videos.jpg" }) {
        ...mediumImage
      }
    }
  `)
  return (
    <Grid columns={[null, null, 2]} space={6}>
      <HyperCardStacked
        title="Dauerausstellung"
        headingElement="h2"
        image={data.dauerausstellung}
        alt="Eine Person sieht sich an einem Laptop die Manuskripte zu Hölderlins Jahreszeitengedichten an."
        description={`Ausgehend von Hölderlins Studienjahren am Tübinger Stift und seinen letzten 36 Lebensjahren im Tübinger Turm verfolgt die Dauerausstellung Hölderlins Arbeit an der Sprache und Metrik seiner Gedichte.`}
        to="/dauerausstellung"
      />
      <HyperCardStacked
        title="Sonderausstellungen"
        headingElement="h2"
        image={data.sonderausstellungen}
        alt="Eine Person sieht sich auf dem Handy die digitale Sonderausstellung ›Hölderlins Orte – Fotografien von Barbara Klemm‹ an."
        description="Unsere digitalen Angebote zu den Sonderausstellungen bereiten auf den Ausstellungsbesuch im Hölderlinturm vor oder bieten die Möglichkeit, das Gesehene noch einmal zu vertiefen."
        to="/sonderausstellungen"
      />
      <HyperCardStacked
        title="Digitale Kurse"
        headingElement="h2"
        image={data.kurse}
        alt="Eine Person steht am Fenster und wählt auf einem Tablet bunte Emojis zu Hölderlins Reiseerlebnissen aus."
        description={`Lust auf Lyrik? Unsere interaktiven Kurse ermöglichen Annäherungen an Hölderlins Sprachkunstwerke. Im Mittelpunkt steht jeweils ein Gedicht von Hölderlin, das aus verschiedenen Perspektiven erschlossen wird. Vom Grundschul- bis ins Erwachsenen-Alter ist für Alle etwas dabei!`}
        to="/kurse"
      />
      <HyperCardStacked
        title="Jahreszeiten-Gedicht-Maschine"
        headingElement="h2"
        alt="Auf einem Tablet sind bunte Kacheln in den Farben blau, grün, orange und gelb zu sehen. Eine Person wählt daraus die grüne Kachel mit der Aufschrift ›Der Frühling‹ aus."
        image={data.soundboard}
        description={`Für die Jahreszeiten-Gedicht-Maschine haben wir ein Frühlings-, ein Sommer-, ein Herbst- und ein Winter-Gedicht jeweils Vers für Vers von verschiedenen Sprecher*innen einlesen lassen. Aus diesen Versatzstücken kann sich jeder einen eigenen Jahreszeiten-Remix zusammenstellen.`}
        to="/jahreszeiten-gedicht-maschine"
      />
      <HyperCardStacked
        title="Workshops"
        headingElement="h2"
        image={data.workshops}
        alt="Eine Tafel mit Magnet-Wörtern aus Hölderlins Gedicht ›Die Linien des Lebens‹. Eine Hand hält das Wort ›Linien‹ zwischen Daumen und Zeigefinger."
        description={`Hier stellen wir Gestaltungsideen zum kreativen Umgang mit der Sprache und anderen Materialien. Mit Tutorials, die Jung und Alt zum Schreiben, Erfinden, Basteln und Zeichnen anregen und garantiert die Langeweile vertreiben.`}
        attribution="David Franck"
        to="/workshops"
      />
      <HyperCardStacked
        title="Aus dem Archiv geholt"
        alt="Eine Vitrine mit einem Manuskript. Darauf liegen dunkelgraue Hefte mit gelbem Rand und Titel."
        headingElement="h2"
        image={data.archiv}
        description={`In der Reihe ›Aus dem Archiv geholt‹ zeigt der Hölderlinturm
            wechselnde Originale aus verschiedenen Sammlungen –
            kommentiert von Gästen aus der Wissenschaft, Kunst und Kultur.`}
        to="/aus-dem-archiv-geholt"
      />
      <HyperCardStacked
        title="Turm-TV"
        headingElement="h2"
        image={data.videos}
        alt="Auf einem Tisch vor einer Bücherwand steht ein Tablet. Darauf sind Videos aus dem Turm-TV zu sehen."
        description={`In Expert*innen-Videos, Interviews und Live-Rundgängen greifen die Beiträge aus dem Turm-TV unterschiedlichste Themen aus Friedrich Hölderlins Biografie, Literatur und Wirkungsgeschichte auf und vermitteln sie.
`}
        to="/turm-tv"
      />
      <HyperCardStacked
        title="Turmgespräche"
        headingElement="h2"
        image={data.podcast}
        alt="Ein Mikrofon neben einem Stapel mit Büchern."
        description={`Lesungen, Podcasts und Interviews setzen sich mit Hölderlin,
              seinen Werken und der Literatur der Gegenwart auseinander.`}
        to="/turmgespraeche"
      />
      <HyperCardStacked
        title="Media-Guide"
        headingElement="h2"
        image={data.mediaguide}
        alt="Eine Person steht vor Hölderlins altem Schreibtisch und hält den Media-Guide mit einem Hörtext zum Schreibtisch in der Hand."
        description={`Mit unserem inklusiven Media-Guide zur Dauerausstellung kann man sich auf eigene Faust einen Weg durch die Ausstellung bahnen: Raum für Raum und Objekt für Objekt oder im Eildurchlauf. Der Media-Guide enthält Inhalte in Leichter Sprache und Gebärdensprache, Angebote für blinde und sehbeeinträchtigte Menschen und in den Sprachen Deutsch, Englisch und Französisch.`}
        to="/mediaguide"
      />
      <HyperCardStacked
        title="Literaturpfad"
        headingElement="h2"
        image={data.literaturpfad}
        alt="Ein Schild mit der Aufschrift »Albert Knapps Geburtshaus«. Eine Person steht davor und hält ein Smartphone mit einem Hör- und Lesetext dazu in der Hand."
        description={`Nirgendwo sonst rückt die europäische Literatur- und Geistesgeschichte so dicht zusammen wie in den engen Häuserreihen der Tübinger Altstadt. Mit dem Tübinger Literaturpfad können Sie die literarischen Orte der Stadt erkunden – digital mit der Literaturpfad-App oder analog mit dem dazugehörigen Buch.`}
        to="/literaturpfad"
      />
    </Grid>
  )
}

export default TeaserDigital
